export default class NaturezaConsignacao {
  constructor(http) {
    this._http = http
  }

  async getListaNaturezasConsignacao() {
    const response = await this._http.get(
      '/api/natureza-consignacao/listaNaturezas',
    )
    return response.data
  }
}
