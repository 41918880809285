<template>
  <div>
    <div class="p-fluid formgrid grid">
      <div class="field col-3 md:col-3">
        <label for="contrato" class="required">Nº Contrato</label>
        <InputText
          id="contrato"
          v-model="v$.proposta.contrato.$model"
          :class="{
            'p-invalid': submitted && v$.proposta.contrato.$invalid,
          }"
          type="text"
          placeholder="Digite o número contrato" />
        <div
          v-if="submitted && v$.proposta.contrato.required.$invalid"
          class="p-error">
          O campo Nº Contrato é obrigatório.
        </div>
      </div>
      <div class="field col-3 md:col-3">
        <label for="valorParcela" class="required">Valor Parcela</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">R$</span>
          <InputNumber
            id="valorParcela"
            v-model="v$.proposta.valorParcela.$model"
            mode="decimal"
            locale="pt-BR"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            :class="{
              'p-invalid': submitted && v$.proposta.valorParcela.$invalid,
            }"
            placeholder="0,00" />
        </div>
        <div
          v-if="submitted && v$.proposta.valorParcela.required.$invalid"
          class="p-error">
          O campo valor parcela é obrigatório.
        </div>
        <div
          v-if="
            submitted && valorParcelaMenorIgualTotalConsignacoesSelecionadas
          "
          class="p-error">
          O campo valor parcela não pode ser maior do que o total a ser
          comprado.
        </div>
      </div>
      <div class="field col-3 md:col-3">
        <label for="prazoTotal" class="required">Prazo</label>
        <InputNumber
          id="prazoTotal"
          v-model="v$.proposta.prazoTotal.$model"
          :min="0"
          :max="999"
          :class="{
            'p-invalid': submitted && v$.proposta.prazoTotal.$invalid,
          }"
          @input="conferirPrazoEDataParcela(v$.proposta.prazoTotal.$model)" />
        <div
          v-if="submitted && v$.proposta.prazoTotal.required.$invalid"
          class="p-error">
          O campo prazo total é obrigatório.
        </div>
      </div>
      <div class="field col-3 md:col-3">
        <label for="carencia" class="required">
          Data da Primeira Parcela:
        </label>
        <Dropdown
          id="carencia"
          v-model="v$.proposta.carencia.$model"
          :options="datasCarencia"
          optionLabel="smesCarencia"
          placeholder="Selecione uma carência"
          :class="{
            'p-invalid': submitted && v$.proposta.carencia.$invalid,
          }">
        </Dropdown>
        <div
          v-if="submitted && v$.proposta.carencia.required.$invalid"
          class="p-error">
          O campo carência é obrigatório
        </div>
      </div>
    </div>
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <label for="obs" class="required">Observação</label>
        <Textarea
          id="obs"
          v-model="v$.proposta.obs.$model"
          :autoResize="true"
          :maxlength="255" />
        <div
          v-if="submitted && v$.proposta.obs.required.$invalid"
          class="p-error">
          O campo observação é obrigatório
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  props: {
    proposta: {
      type: Object,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: true,
    },
    datasCarencia: {
      type: Array,
      required: true,
    },
    valorParcelaMenorIgualTotalConsignacoesSelecionadas: {
      type: Boolean,
      required: true,
    },
    conferirPrazoEDataParcela: {
      type: Function,
      required: true,
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      localProposta: this.proposta,
    }
  },
  watch: {
    proposta(newValue) {
      this.localProposta = newValue
    },
    localProposta: {
      handler(newValue) {
        this.$emit('update:proposta', newValue)
      },
      deep: true,
    },
  },

  validations() {
    return {
      proposta: {
        rubrica: {
          required,
        },
        contrato: {
          required,
        },
        valorParcela: {
          required,
        },
        prazoTotal: {
          required,
        },
        carencia: {
          required,
        },
        obs: {
          required,
        },
      },
    }
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
