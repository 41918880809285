var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"contrato"}},[_vm._v("Nº Contrato")]),_c('InputText',{class:{
          'p-invalid': _vm.submitted && _vm.v$.proposta.contrato.$invalid,
        },attrs:{"id":"contrato","type":"text","placeholder":"Digite o número contrato"},model:{value:(_vm.v$.proposta.contrato.$model),callback:function ($$v) {_vm.$set(_vm.v$.proposta.contrato, "$model", $$v)},expression:"v$.proposta.contrato.$model"}}),(_vm.submitted && _vm.v$.proposta.contrato.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Nº Contrato é obrigatório. ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.proposta.rubrica &&
        Object.keys(_vm.proposta.rubrica).length > 0 &&
        _vm.proposta.rubrica.atividade.id !== 2 &&
        _vm.proposta.rubrica.atividade.id !== 9
      ),expression:"\n        proposta.rubrica &&\n        Object.keys(proposta.rubrica).length > 0 &&\n        proposta.rubrica.atividade.id !== 2 &&\n        proposta.rubrica.atividade.id !== 9\n      "}],staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"agencia"}},[_vm._v("Agência")]),_c('InputText',{attrs:{"id":"agencia","type":"text","maxlength":"4"},model:{value:(_vm.localProposta.agencia),callback:function ($$v) {_vm.$set(_vm.localProposta, "agencia", $$v)},expression:"localProposta.agencia"}})],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"valorParcela"}},[_vm._v("Valor Parcela")]),_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_vm._v("R$")]),_c('InputNumber',{class:{
            'p-invalid': _vm.submitted && _vm.v$.proposta.valorParcela.$invalid,
          },attrs:{"id":"valorParcela","mode":"decimal","locale":"pt-BR","minFractionDigits":2,"maxFractionDigits":5,"placeholder":"0,00"},model:{value:(_vm.v$.proposta.valorParcela.$model),callback:function ($$v) {_vm.$set(_vm.v$.proposta.valorParcela, "$model", $$v)},expression:"v$.proposta.valorParcela.$model"}})],1),(_vm.submitted && _vm.v$.proposta.valorParcela.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo valor parcela é obrigatório. ")]):_vm._e(),(
          _vm.submitted && _vm.valorParcelaMenorIgualTotalConsignacoesSelecionadas
        )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo valor parcela não pode ser maior do que o total a ser comprado. ")]):_vm._e()]),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"prazoTotal"}},[_vm._v("Prazo")]),_c('InputNumber',{class:{
          'p-invalid': _vm.submitted && _vm.v$.proposta.prazoTotal.$invalid,
        },attrs:{"id":"prazoTotal","min":0,"max":999},on:{"input":function($event){return _vm.conferirPrazoEDataParcela(_vm.v$.proposta.prazoTotal.$model)}},model:{value:(_vm.v$.proposta.prazoTotal.$model),callback:function ($$v) {_vm.$set(_vm.v$.proposta.prazoTotal, "$model", $$v)},expression:"v$.proposta.prazoTotal.$model"}}),(_vm.submitted && _vm.v$.proposta.prazoTotal.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo prazo total é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"carencia"}},[_vm._v(" Data da Primeira Parcela: ")]),_c('Dropdown',{class:{
          'p-invalid': _vm.submitted && _vm.v$.proposta.carencia.$invalid,
        },attrs:{"id":"carencia","options":_vm.datasCarencia,"optionLabel":"smesCarencia","placeholder":"Selecione uma carência"},model:{value:(_vm.v$.proposta.carencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.proposta.carencia, "$model", $$v)},expression:"v$.proposta.carencia.$model"}}),(_vm.submitted && _vm.v$.proposta.carencia.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo carência é obrigatório ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12 md:col-12"},[_c('label',{staticClass:"required",attrs:{"for":"obs"}},[_vm._v("Observação")]),_c('Textarea',{attrs:{"id":"obs","autoResize":true,"maxlength":255},model:{value:(_vm.v$.proposta.obs.$model),callback:function ($$v) {_vm.$set(_vm.v$.proposta.obs, "$model", $$v)},expression:"v$.proposta.obs.$model"}}),_vm._v(" "),(_vm.submitted && _vm.v$.proposta.obs.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo observação é obrigatório ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }